<template>
  <c-box
    w="100%"
    max-width="1270px"
    mx="auto"
    :my="['0', '16px']"
    h="auto"
    bg="#FFF"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['1rem', '1.5rem']"
    :min-height="['unset', '74vh']"
  >
    <Stepper
      margin-top="16px"
      :margin-bottom="['12px', '20px']"
      :steps="[
        {
          label: 'Pilih Program',
          isCurrentStep: true,
        },
        {
          label: 'Informasi Pribadi',
        },
        {
          label: 'Periksa',
        },
        {
          label: 'Pembayaran',
        },
      ]"
    />

    <c-box
      :padding="['0', '1rem']"
      :max-width="['auto', '900px']"
      margin="auto"
    >
      <c-box
        v-if="!!product?.name"
        margin-bottom="16px"
      >
        <CardSimpleDetailProgram
          :product-slug="product?.slug"
          :product-description="product?.overview"
          :product-name="product?.name"
          :product-id="product?.id"
          :product-photo-src="product?.photoUrl"
        />
      </c-box>
      <c-box margin-bottom="16px" />

      <c-box
        v-show="!isLoadingProducts && !!$route.query?.product_id"
        height="100%"
      >
        <TableProductComparation
          :product-id="$route.query?.product_id || ''"
        >
          <template #button="{ productService }">
            <BaseButton
              v-chakra="{
                color: 'primary.400',
                fontWeight: ['400', '500'],
                fontSize: ['12px', '18px']
              }"
              size="small"
              variant="contained"
              w="100%"
              border-radius="1000px"
              color="secondary"
              :is-loading="isLoading.buyNow"
              @click="handleNext({
                product_id: productService?.productId,
                product_service_id: productService?.id,
                duration: productService?.duration,
              })"
            >
              Beli Sekarang
            </BaseButton>
          </template>
        </TableProductComparation>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import Stepper from '@/components/elements/stepper.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { mapActions, mapGetters } from 'vuex'
import CardSimpleDetailProgram from '@/components/card-simple-detail-program.vue'
import TableProductComparation from '@/components/table-product-comparation.vue'

export default {
  name: 'BuyProgram',
  components: {
    TableProductComparation,
    CardSimpleDetailProgram,
    Stepper,
    BaseButton,
  },
  data() {
    return {
      routeQuery: {
        categoryId: this.$route.query?.category_id,
        productId: this.$route.query?.product_id,
        productServiceId: this.$route.query?.product_service_id,
        duration: this.$route.query?.duration, 
      },
      isLoadingProducts: false,
      durations: [],
      selectedDuration: null,
      programGrouping: [],
      isLoading: {
        buyNow: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      productCategory: 'general/productCategory',
      isAuthenticated: 'auth/isAuthenticated',
      products: 'clients/productsByName',
      product: 'clients/product',
    }),
  },
  mounted() {
    this.detailProduct(this.$route.query?.product_id)
    if (!this.$route.query?.product_id) {
      this.$toast({
        status: 'error',
        title: 'Error',
        description: 'something went wrong',
        duration: 3000,
      })
    }
  },
  methods: {
    ...mapActions({
      loadProductCategory: 'general/loadProductCategory',
      listProducts: 'clients/getProductByName',
      detailProduct: 'clients/getProductById',
    }),
    handleNext({
      product_id,
      product_service_id,
      duration,
    }) {
      if (this.isAuthenticated) {
        this.$router.push({
          name: 'client.buy.step-3',
          query: {
            product_id,
            product_service_id,
            duration,
          },
        })
        return
      }
      this.$router.push({ 
        name: 'client.buy.step-2',
        query: {
          product_id,
          product_service_id,
          duration,
        },
      })
    },
  },
}
</script>
