var render = function () {
  var _vm$product, _vm$product2, _vm$product3, _vm$product4, _vm$product5, _vm$product6, _vm$$route$query, _vm$$route$query2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1270px",
      "mx": "auto",
      "my": ['0', '16px'],
      "h": "auto",
      "bg": "#FFF",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('Stepper', {
    attrs: {
      "margin-top": "16px",
      "margin-bottom": ['12px', '20px'],
      "steps": [{
        label: 'Pilih Program',
        isCurrentStep: true
      }, {
        label: 'Informasi Pribadi'
      }, {
        label: 'Periksa'
      }, {
        label: 'Pembayaran'
      }]
    }
  }), _c('c-box', {
    attrs: {
      "padding": ['0', '1rem'],
      "max-width": ['auto', '900px'],
      "margin": "auto"
    }
  }, [!!((_vm$product = _vm.product) !== null && _vm$product !== void 0 && _vm$product.name) ? _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('CardSimpleDetailProgram', {
    attrs: {
      "product-slug": (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.slug,
      "product-description": (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.overview,
      "product-name": (_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : _vm$product4.name,
      "product-id": (_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : _vm$product5.id,
      "product-photo-src": (_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : _vm$product6.photoUrl
    }
  })], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoadingProducts && !!((_vm$$route$query = _vm.$route.query) !== null && _vm$$route$query !== void 0 && _vm$$route$query.product_id),
      expression: "!isLoadingProducts && !!$route.query?.product_id"
    }],
    attrs: {
      "height": "100%"
    }
  }, [_c('TableProductComparation', {
    attrs: {
      "product-id": ((_vm$$route$query2 = _vm.$route.query) === null || _vm$$route$query2 === void 0 ? void 0 : _vm$$route$query2.product_id) || ''
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref) {
        var productService = _ref.productService;
        return [_c('BaseButton', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              color: 'primary.400',
              fontWeight: ['400', '500'],
              fontSize: ['12px', '18px']
            },
            expression: "{\n              color: 'primary.400',\n              fontWeight: ['400', '500'],\n              fontSize: ['12px', '18px']\n            }"
          }],
          attrs: {
            "size": "small",
            "variant": "contained",
            "w": "100%",
            "border-radius": "1000px",
            "color": "secondary",
            "is-loading": _vm.isLoading.buyNow
          },
          on: {
            "click": function click($event) {
              return _vm.handleNext({
                product_id: productService === null || productService === void 0 ? void 0 : productService.productId,
                product_service_id: productService === null || productService === void 0 ? void 0 : productService.id,
                duration: productService === null || productService === void 0 ? void 0 : productService.duration
              });
            }
          }
        }, [_vm._v(" Beli Sekarang ")])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }